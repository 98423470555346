import React, { memo } from 'react'
import { ModalConnected } from 'kat/lib/com/widget/Modal/ModalConnected'
import { ModalLayoutProps } from './types/ModalLayoutProps'
import { ModalLayoutRouterConnected } from 'kat/lib/com/router/ModalLayoutRouter/ModalLayoutRouterConnected'

export const ModalLayout = memo(
  ({ type, isEndToEndTesting, onCancel, isMediumScreen }: ModalLayoutProps) => {
    const isEndToEndTestingClassName = isEndToEndTesting
      ? ' isEndToEndTesting'
      : ''
    const isMediumScreenClassName = isMediumScreen ? ' isMediumScreen' : ''

    return (
      <ModalConnected
        className={
          'ModalLayout App ' +
          isEndToEndTestingClassName +
          isMediumScreenClassName
        }
        visible={!!type}
        onCancel={onCancel}
      >
        <ModalLayoutRouterConnected />
      </ModalConnected>
    )
  }
)
