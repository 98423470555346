import { OneLayoutProps } from './types/OneLayoutProps'
import { PageSpinnerWidgetConnected } from 'kat/lib/com/widget/PageSpinnerWidget/PageSpinnerWidgetConnected'
import React from 'react'
import { appStyles } from 'styles/appStyles'
import { OneLayoutRouterConnected } from 'kat/lib/com/router/OneLayoutRouter/OneLayoutRouterConnected'
import { css } from 'emotion'
import { StartMainConnected } from '../../main/StartMain/StartMainConnected'

export const OneLayout = ({
  isBigScreen,
  className = '',
  startRoute,
}: OneLayoutProps) => {
  return (
    <div
      className={`OneLayout ${css`
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 0px 0px 128px 0px;
      `} ${className}`}
    >
      <PageSpinnerWidgetConnected />

      <main
        className={`${css`
          width: 100%;
          max-width: ${isBigScreen ? appStyles.BIG_LAYOUT_WIDTH : 'initial'};
          margin-left: ${isBigScreen ? 'auto' : 'initial'};
          margin-right: ${isBigScreen ? 'auto' : 'initial'};
          padding-top: ${isBigScreen
            ? appStyles.spacing.big
            : appStyles.spacing.medium};
        `} ${
          isBigScreen
            ? css`
                > * {
                  padding: 0;
                }
              `
            : css`
                > * {
                  max-width: 540px;
                  margin: 0 auto;
                  padding: 16px;
                }
              `
        }`}
      >
        {startRoute && <StartMainConnected />}
        <OneLayoutRouterConnected />
      </main>
    </div>
  )
}
