import { css } from 'emotion'
import { appStyles } from './appStyles'

export const globalStyles = css`
  color: ${appStyles.textColor.primary.medium};
  font-family: Helvetica, Arial, sans-serif;
  font-size: 17px;
  letter-spacing: 0.2px;
  font-weight: ${appStyles.fontWeight.medium};
  background-color: ${appStyles.backgroundColor.neutral.lighter};

  img {
    overflow: hidden; /* in case of alt text */
  }

  .sandbox {
    margin-top: 100px;
  }

  &.isEndToEndTesting {
    input,
    textarea,
    [contenteditable] {
      caret-color: #fff; /* hide the carret for= snapshot testing */
    }

    transform: scale(0.5);
    transform-origin: top left;
  }

  p {
    line-height: 1.45em;

    * {
      line-height: 1.45em;
    }
  }

  h1 {
    font-size: 1.1em;
  }

  h2 {
    font-size: 1em;
  }

  &.isMediumScreen {
    h1 {
      font-size: 1.5em;
    }

    h2 {
      font-size: 1.25em;
    }
  }

  strong {
    font-weight: ${appStyles.fontWeight.bigger};
  }
`
