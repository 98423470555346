import { appRoutesBase } from 'kat/lib/routes/appRoutesBase'
import { appRoutesBaseDefault } from 'kat/lib/routes/appRoutesBaseDefault'

export const appRoutes = appRoutesBase.extends({
  ...appRoutesBaseDefault,
  startRoute: {
    pathname: '/start',
    isOneLayout: true,
  },
})()
