import { stripeSdk } from 'kat/lib/sdks/stripe/stripeSdk'
import { firebaseSdk } from 'kat/lib/sdks/firebase/firebaseSdk'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/messaging'
import { sentrySdk } from 'kat/lib/sdks/sentry/sentrySdk'
import { firebaseMessagingSdk } from 'kat/lib/sdks/firebase/firebaseMessagingSdk'
import { apiRoutesBase } from 'kat/lib/routes/apiRoutesBase'

import {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  SENTRY_DSN,
  STRIPE_ID,
  PUBLIC_VAPID_KEY,
  FIREBASE_FUNCTIONS_BASE_URL,
  FIREBASE_MEASUREMENT_ID,
} from 'constants/sdkConstants'

firebaseSdk.init({
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
})

firebaseMessagingSdk.init({ publicVapidKey: PUBLIC_VAPID_KEY })

if (SENTRY_DSN) {
  sentrySdk.init({ dsn: SENTRY_DSN })
}

apiRoutesBase.setBaseUrl(FIREBASE_FUNCTIONS_BASE_URL)

stripeSdk.init({ stripeId: STRIPE_ID })
