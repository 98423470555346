import { StateReducers } from 'state/reducers/types/StateReducers'
import { AppRoute } from 'kat/lib/routes/types/AppRoute'
import { UrlQuery } from '../../functions/routes/types/UrlQuery'
import { getBackLocationBase } from 'kat/lib/state/getters/getBackLocationBase'

interface Props {
  state: StateReducers
}

interface Return {
  route?: AppRoute
  query?: UrlQuery
  withCurrentQuery?: boolean
}

// This functions gets executed alot, make sure its simple
export const getBackLocation = getBackLocationBase.extends(
  (_props: Props): Return => {
    return {}
  }
)
