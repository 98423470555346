import { OneLayoutMapStateReturnType } from './types/OneLayoutMapStateReturnType'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsMediumScreen } from 'kat/lib/state/getters/getIsMediumScreen'
import { getIsBigScreen } from 'kat/lib/state/getters/getIsBigScreen'
import { getCurrentWorkspaceId } from 'kat/lib/state/getters/getCurrentWorkspaceId'
import { getObjectFirstItem } from 'kat/lib/utilities/get/getObjectFirstItem'

export const OneLayoutMapState = (
  state: StateReducers
  // props: OneLayoutConnectProps,
): OneLayoutMapStateReturnType => {
  const isRoutes = state.currentLocation?.isRoutes
  const isMediumScreen = getIsMediumScreen({ state })
  const isBigScreen = getIsBigScreen({ state })
  const workspaces = state.workspaces || undefined
  const { key: workspaceId } = getObjectFirstItem(workspaces)
  const showWorkspaceHeader = !!(
    state.currentUserId && getCurrentWorkspaceId({ state })
  )
  const currentUserid = state.currentUserId || undefined

  return {
    isBigScreen,
    showWorkspaceHeader,
    isMediumScreen,
    workspaceId,
    currentUserid,
    ...isRoutes,
  }
}
