import { combinedReducersBase } from 'kat/lib/state/combinedReducersBase'
import { combineReducers } from 'redux'
import { StateReducers } from './types/StateReducers'
import { SignOutSuccessAction } from 'kat/lib/actionCreators/auth/types/SignOutSuccessAction'

const appReducer = combineReducers<StateReducers>({
  ...combinedReducersBase,
})

export const initialState = appReducer({}, { type: 'INITIAL' }) as StateReducers

export const rootReducer = (
  state: StateReducers,
  action: SignOutSuccessAction
) => {
  if (action.type === 'SIGN_OUT_SUCCESS') {
    const sessionStorage = state.sessionStorage || undefined
    const cookieStorage = state.cookieStorage || undefined
    const isEndToEndTesting = sessionStorage && sessionStorage.isEndToEndTesting
    const windowMeta = state.windowMeta || undefined

    const newState: StateReducers = {
      ...initialState,
      sessionStorage: {
        ...initialState.sessionStorage,
        isEndToEndTesting,
      },
      windowMeta,
      cookieStorage,
    }

    return appReducer(newState, action)
  }

  return appReducer(state, action)
}
