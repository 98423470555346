import { connect } from 'react-redux'
import { ModalLayout } from './ModalLayout'
import { ModalLayoutMapState } from './ModalLayoutMapState'
import { ModalLayoutMapDispatch } from './ModalLayoutMapDispatch'

import { ModalLayoutMapStateReturnType } from './types/ModalLayoutMapStateReturnType'
import { ModalLayoutMapDispatchReturnType } from './types/ModalLayoutMapDispatchReturnType'
import { ModalLayoutConnectedProps } from './types/ModalLayoutConnectedProps'


export const ModalLayoutConnected = connect<
  ModalLayoutMapStateReturnType,
  ModalLayoutMapDispatchReturnType,
  ModalLayoutConnectedProps>(ModalLayoutMapState,
    ModalLayoutMapDispatch)(ModalLayout)
