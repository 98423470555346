// Initialize stuff
import './utilities/initalizeSdks'
import 'functions/routes/appRoutes'
import 'functions/routes/queryNames'
import 'kat/lib/utilities/polyfills'
import './state/getters/getBackLocation'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
// import { createWindowClickAction } from 'actionCreators/createWindowClickAction'
import { createAppStore } from 'kat/lib/state/createAppStore'
import { rootReducer } from 'state/reducers/rootReducer'
import { flows } from 'flows/flows'
import { reduxLogger } from 'state/reduxLogger'
import { envConfig } from './functions/envConfig/envConfig'
import { WindowSelectionAction } from 'kat/lib/actionCreators/types/WindowSelectionAction'
import { WindowResizeAction } from 'kat/lib/actionCreators/types/WindowResizeAction'
import { getViewPortWidth } from 'kat/lib/utilities/getViewPortWidth'
import { getViewPortHeight } from 'kat/lib/utilities/getViewPortHeight'
import { ReduxRouterConnected } from 'kat/lib/com/router/ReduxRouter/ReduxRouterConnected'
import { AppConnected } from './com/App/AppConnected'

const rootEl = document.getElementById('root') as HTMLElement
const history = createBrowserHistory()

const store = createAppStore({
  rootReducer: rootReducer as any,
  flows,
  history,
  reduxLogger,
  enableLogger:
    envConfig.environment === 'local' ||
    envConfig.environment === 'development',
})

const dispatchWindowResize = () => {
  var width = getViewPortWidth()
  var height = getViewPortHeight()

  store.dispatch<WindowResizeAction>({
    type: 'WINDOW_RESIZE',
    description: 'index',
    values: {
      width,
      height,
    },
  })
}

const dispatchWindowSelection = () => {
  var selection = window.getSelection()

  store.dispatch<WindowSelectionAction>({
    type: 'WINDOW_SELECTION_ACTION',
    description: 'index',
    textSelection: selection?.toString() || '',
  })
}

window.onresize = dispatchWindowResize

window.storeDispatch = store.dispatch
window.document.onselectionchange = dispatchWindowSelection

const providerApp = (
  <Provider store={store}>
    <ReduxRouterConnected history={history}>
      <AppConnected />
    </ReduxRouterConnected>
  </Provider>
)

ReactDOM.render(providerApp, rootEl)

if (false) {
  // Dont need offline mode
  serviceWorker.register()
} else {
  serviceWorker.unregister()
}
