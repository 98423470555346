import { connect } from 'react-redux'
import { OneLayout } from './OneLayout'
import { OneLayoutMapState } from './OneLayoutMapState'
import { OneLayoutMapDispatch } from './OneLayoutMapDispatch'
import { OneLayoutMapStateReturnType } from './types/OneLayoutMapStateReturnType'
import { OneLayoutMapDispatchReturnType } from './types/OneLayoutMapDispatchReturnType'
import { OneLayoutConnectedProps } from './types/OneLayoutConnectedProps'

export const OneLayoutConnected = connect<
  OneLayoutMapStateReturnType,
  OneLayoutMapDispatchReturnType,
  OneLayoutConnectedProps
>(
  OneLayoutMapState,
  OneLayoutMapDispatch
)(OneLayout)
