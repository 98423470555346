import { Dispatch, Action } from 'redux'
import { ToggleAction } from 'actionCreators/ToggleAction'

import {
  // ModalLayoutConnectedProps,
  ModalLayoutMapDispatchReturnType,
} from './types'

export const ModalLayoutMapDispatch = (
  dispatch: Dispatch<Action>
  // props: ModalLayoutConnectedProps,
): ModalLayoutMapDispatchReturnType => {
  return {
    onCancel: () => {
      dispatch<ToggleAction>({
        type: 'TOGGLE',
        description: 'ModalLayoutMapDispatch',
        toggles: {
          showModal: false,
        },
      })
    },
  }
}
