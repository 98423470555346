import { StateReducers } from '../../../state/reducers/types/StateReducers'
import { StartMainConnectedProps } from './types/StartMainConnectedProps'
import { StartMainMapStateReturnType } from './types/StartMainMapStateReturnType'

export const StartMainMapState = (
  _state: StateReducers,
  _props: StartMainConnectedProps,
): StartMainMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
