import { all, spawn, call } from 'redux-saga/effects'

export const flows = function* root() {
  const allFlows: any[] = []

  yield all(
    allFlows.map((flow) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(flow)
            break
          } catch (e) {
            console.log(e)
          }
        }
      })
    )
  )
}
