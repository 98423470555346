import { getIsRoutesFromStateAs } from './../../../state/getters/getIsRoutesFromStateAs'
import { StateReducers } from '../../../state/reducers/types/StateReducers'
import { AppMainConnectedProps } from './types/AppMainConnectedProps'
import { AppMainMapStateReturnType } from './types/AppMainMapStateReturnType'
import { getIsMediumScreen } from 'kat/lib/state/getters/getIsMediumScreen'
import { getIsBigScreen } from 'kat/lib/state/getters/getIsBigScreen'

export const AppMainMapState = (
  state: StateReducers,
  _props: AppMainConnectedProps
): AppMainMapStateReturnType => {
  const workspaceHomeRoute = state.currentLocation?.isRoutes?.workspaceHomeRoute
  const isRoutes = getIsRoutesFromStateAs({ state })
  const isBigScreen = getIsBigScreen({ state })
  const showOneLayout =
    !!isRoutes?.isOneLayoutRoute || !!isRoutes?.isNotFoundRoute
  const showDashboardLayout = !!isRoutes?.isDashboardLayoutRoute

  const showMinimalLayout = isRoutes?.isMinimalLayoutRoute
  const isMediumScreen = getIsMediumScreen({ state })
  const windowHeight = state.windowMeta?.height
  const windowWidth = state.windowMeta?.width
  const textSelection = state.windowMeta?.textSelection

  return {
    isBigScreen,
    isMediumScreen,
    workspaceHomeRoute,
    showOneLayout,
    showDashboardLayout,
    showMinimalLayout,
    windowHeight,
    windowWidth,
    textSelection,
  }
}
