// import { Dispatch, Action } from 'redux'

import {
  // AppConnectedProps,
  AppMapDispatchReturnType,
} from './types/AppMapDispatchReturnType'

export const AppMapDispatch = (): // dispatch: Dispatch<Action>,
// props: AppConnectedProps,
AppMapDispatchReturnType => {
  return {}
}
