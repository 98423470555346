import { createLogger } from 'redux-logger'
import { IncrementWatcherActionBase } from 'kat/lib/actionCreators/types/IncrementWatcherActionBase'

const incrementWatcher: IncrementWatcherActionBase['type'] = 'INCREMENT_WATCHER'

export const reduxLogger = createLogger({
  predicate: (_getState, action) => {
    const { type } = action
    // return true
    return type !== incrementWatcher
  },
})
