import React, { FC, memo, useState } from 'react'
import { StartMainProps } from './types/StartMainProps'
import { css } from 'emotion'
import flippers from './turtle-flippers.gif'
import agree from './agree.gif'
import chair from './chair.gif'
import bump from './bump.gif'
import sadLips from './sad-lips.gif'
import rock from './rock.gif'
import fried from './fried.gif'
import dance from './dance.gif'
import handsome from './handsome.gif'
import dogTongue from './dog-tongue.gif'
import chips from './chips.gif'
import bonk from './bonk.gif'
import crown from './crown.gif'
import carrot from './carrot.gif'
import snowboard from './snowboard.gif'
import blackbird from './blackbird.gif'
import snorkeling from './snorkeling.gif'
import cuddle from './cuddle.gif'
import idk from './idk.gif'
import rubFace from './rub-face.gif'
import stretching from './stretching.gif'
import wimper from './wimper.gif'
import coupleCuddle from './couple-cuddle.gif'
import piggyDressUp from './piggy-dress-up.gif'
import sushiCheers from './sushi-cheers.gif'
import chaiseCat from './chaise-cat.gif'
import puppyYawning from './puppy-yawning.gif'
import catScratch from './cat-scratch.gif'
import dogDangle from './dog-dangle.gif'
import tada from './tada.gif'

const items2021 = [
  {
    description: 'I love when you do Flippers and become an Outlaw!',
    img: flippers,
  },
  { description: 'I love when you agree and face snuggle me.', img: agree },
  {
    description: 'I love when you do chair and fit perfectly in my legs.',
    img: chair,
  },
  { description: 'I love when you do the sad lip bump.', img: sadLips },
  { description: 'I love when you ask me to give you a bump.', img: bump },
  { description: 'I love steeling rocks with you.', img: rock },
  {
    description: 'I love the way you eat fried chicken and see you so happy.',
    img: fried,
  },
  {
    description: 'I love the shower dance ritual we do before getting out.',
    img: dance,
  },
  {
    description:
      'I love the way to like to try new things like flying, snowboarding and skating.',
    img: snowboard,
  },
  { description: 'I love going to national parks with you.', img: snorkeling },
]

const items2022 = [
  {
    description: 'I love when you call me handsome man.',
    img: handsome,
  },
  { description: 'I love when you go Crown!', img: crown },
  { description: 'I love to carrot with you.', img: carrot },
  {
    description:
      'I love when you put your leg over mine in bed (during winter)',
    img: coupleCuddle,
  },
  {
    description: 'I love to snuggle on the sofa with you.',
    img: cuddle,
  },
  {
    description: 'I love it when you say I dont know.',
    img: idk,
  },
  {
    description: 'I love it when you whimper like a puppy.',
    img: wimper,
  },
  {
    description: 'I love it when you rub your face against mind while purring.',
    img: rubFace,
  },
  {
    description: 'I love it when you fix my clothes.',
    img: piggyDressUp,
  },
  {
    description: 'I love it when you Nigiri me!',
    img: stretching,
  },
]

const items2023 = [
  {
    description: 'I love when you fall asleep with your mouth opened.',
    img: dogTongue,
  },
  { description: 'I love it when we go Bonk!', img: bonk },
  {
    description: 'I love when you sing songs on our road trip.',
    img: blackbird,
  },
  {
    description: 'I love when you go crunchi crunchi.',
    img: chips,
  },
  {
    description: 'I love it when we bink before eating.',
    img: sushiCheers,
  },
  {
    description: 'I love it when we chaise time.',
    img: chaiseCat,
  },
  {
    description: 'I love it when you go ngah.',
    img: puppyYawning,
  },
  {
    description: 'I love it when you scratch my tummy.',
    img: catScratch,
  },
  {
    description: 'I love it when you dangle your feet.',
    img: dogDangle,
  },
  {
    description: 'I love it when you go TA DA!',
    img: tada,
  },
]

const itemGroups = [
  { title: '10 things I love about you in 2021', items: items2021 },
  { title: '10 things I love about you in 2022', items: items2022 },
  { title: '10 things I love about you in 2023', items: items2023 },
]

export const StartMain: FC<StartMainProps> = memo(({ styles = '' }) => {
  const [showNumber, setShowNumber] = useState<number | null>()
  const [showGroup, setShowGroup] = useState<number | null>()
  return (
    <div
      className={
        'StartMain ' +
        css`
          min-height: 100vh;
          ${styles}
        `
      }
    >
      {itemGroups.map(({ title, items }, i) => {
        return (
          <>
            <h1
              style={{ marginTop: '32px' }}
              onClick={() => {
                setShowGroup(i)
                setShowNumber(null)
              }}
            >
              {title}
            </h1>
            {showGroup === i && (
              <ol>
                {items.map(({ description, img }, i) => {
                  return (
                    <li
                      key={i}
                      className={css`
                        margin-top: 16px;
                      `}
                    >
                      {showNumber === i && (
                        <>
                          {description}
                          <img
                            className={css`
                              margin-top: 8px;
                              display: block;
                            `}
                            alt="description"
                            src={img}
                          />
                        </>
                      )}
                      {showNumber !== i && (
                        <button
                          className={css`
                            padding: 16px 32px;
                            font-size: 24px;
                            background-color: orange;

                            &:hover {
                              animation: background-color-change 3s infinite;
                            }
                          `}
                          onClick={() => {
                            setShowNumber(i)
                          }}
                          type="button"
                        >
                          {i + 1}
                        </button>
                      )}
                    </li>
                  )
                })}
              </ol>
            )}
          </>
        )
      })}
    </div>
  )
})
