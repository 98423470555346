import React, { FC, memo, useRef } from 'react'
import { AppMainProps } from './types/AppMainProps'
import { ModalLayoutConnected } from 'com/layout/ModalLayout/ModalLayoutConnected'
import { NotificationPermissionWatcherConnected } from 'kat/lib/com/watcher/NotificationPermissionWatcher/NotificationPermissionWatcherConnected'
import { EnableNotificationMessageConnected } from 'kat/lib/com/message/EnableNotificationMessage/EnableNotificationMessageConnected'
import { OneLayoutConnected } from '../../layout/OneLayout/OneLayoutConnected'
import { css } from 'emotion'
import { ServerErrorMain } from 'kat/lib/com/main/ServerErrorMain/ServerErrorMain'
import { globalStyles } from '../../../styles/globalStyles'
import { SCROLL_CONTAINER_ID } from 'kat/lib/constants/domConstants'
import { PageSpinnerWidgetConnected } from 'kat/lib/com/widget/PageSpinnerWidget/PageSpinnerWidgetConnected'

export const AppMain: FC<AppMainProps> = memo(
  ({
    className = '',
    isBigScreen,
    showOneLayout,
    showError,
    showAppUpdated,
    isMediumScreen,
    windowHeight,
  }) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const slideContainerRef = useRef<HTMLDivElement>(null)

    return (
      <div
        className={`AppMain ${className} ${css`
          display: flex;
          flex-direction: column;
          justify-content: stretch;
          height: ${windowHeight}px;
          overflow: hidden;
        `} ${globalStyles} ${
          !isBigScreen
            ? css`
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
              `
            : ''
        } ${isMediumScreen ? 'isMediumScreen' : ''}`}
      >
        {showError && <ServerErrorMain />}

        {!showAppUpdated && !showError && (
          <>
            <PageSpinnerWidgetConnected />

            <ModalLayoutConnected />

            <EnableNotificationMessageConnected />

            <NotificationPermissionWatcherConnected />

            <div
              id={SCROLL_CONTAINER_ID}
              ref={scrollContainerRef}
              className={css`
                overflow: auto;
                flex: 1;
              `}
            >
              <div
                ref={slideContainerRef}
                className={css`
                  width: 100%;
                `}
              >
                {showOneLayout && <OneLayoutConnected />}
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
)
