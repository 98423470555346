import { queryNamesBase } from 'kat/lib/routes/queryNamesBase'
import { queryNamesBaseDefault } from 'kat/lib/routes/queryNamesBaseDefault'

export const queryNames = queryNamesBase.extends({
  ...queryNamesBaseDefault,
  taskId: 'taskId',
  taskItemId: 'taskItemId',
  planId: 'planId',
  whichMain: 'whichMain',
  labelId: 'labelId',
  replyToTaskItemId: 'replyToTaskItemId',
})()
