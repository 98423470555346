import * as React from 'react'
import { AppProps } from './types/AppProps'
import { sentrySdk } from 'kat/lib/sdks/sentry/sentrySdk'
import { SentrySeverity } from 'kat/lib/sdks/sentry/SentrySeverity'
import { SENTRY_DSN } from '../../constants/sdkConstants'
import { AppMainConnected } from '../main/AppMain/AppMainConnected'

interface State {
  hasError: boolean | 'ChunkLoadError'
}

export class App extends React.Component<AppProps, State> {
  constructor(props: AppProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: error?.name === 'ChunkLoadError' ? 'ChunkLoadError' : true,
    }
  }

  componentDidCatch(error: any, _errorInfo: any) {
    const errorString: string =
      typeof error === 'string' ? error : JSON.stringify(error)

    if (SENTRY_DSN) {
      sentrySdk().captureMessage(
        `getDerivedStateFromError: ${errorString}`,
        SentrySeverity.Critical
      )
    }
  }

  render() {
    return (
      <AppMainConnected
        showError={!!this.state.hasError}
        showAppUpdated={this.state.hasError === 'ChunkLoadError'}
      />
    )
  }
}
