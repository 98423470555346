// import { Dispatch, Action } from 'redux'

import {
  // OneLayoutConnectedProps,
  OneLayoutMapDispatchReturnType,
} from './types/OneLayoutMapDispatchReturnType'

export const OneLayoutMapDispatch = (): // dispatch: Dispatch<Action>,
// props: OneLayoutConnectedProps,
OneLayoutMapDispatchReturnType => {
  return {}
}
