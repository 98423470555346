const env = process && process.env // Can't be const { env } = process, dont know why

export const SENTRY_DSN = env.REACT_APP_SENTRY_DSN

export const STRIPE_ID =
  env.REACT_APP_STRIPE_ID || 'pk_test_JCrZ5EcWTFHzLqTJygfzvqi700c7zPZOyL'

export const FIREBASE_API_KEY =
  env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyARGAj5Euii1XePFzBbYosWid-3DKZ6vBQ'
export const FIREBASE_PROJECT_ID =
  env.REACT_APP_FIREBASE_PROJECT_ID || 'scrumie-local-d3601'
export const FIREBASE_AUTH_DOMAIN =
  env.REACT_APP_FIREBASE_AUTH_DOMAIN || `${FIREBASE_PROJECT_ID}.firebaseapp.com`
export const FIREBASE_DATABASE_URL =
  env.REACT_APP_FIREBASE_DATABASE_URL ||
  `https://${FIREBASE_PROJECT_ID}.firebaseio.com`
export const FIREBASE_STORAGE_BUCKET =
  env.REACT_APP_FIREBASE_STORAGE_BUCKET || `${FIREBASE_PROJECT_ID}.appspot.com`
export const FIREBASE_MESSAGING_SENDER_ID =
  env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '820687974037'
export const FIREBASE_APP_ID =
  env.REACT_APP_FIREBASE_APP_ID || '1:820687974037:web:96b340a74fffd7e494f59c'

export const FIREBASE_FUNCTIONS_BASE_URL =
  env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL ||
  `https://us-central1-${FIREBASE_PROJECT_ID}.cloudfunctions.net`

export const PUBLIC_VAPID_KEY =
  env.REACT_APP_PUBLIC_VAPID_KEY ||
  'BGYI2WMkr4SBm_RsNoHJ1eJcg4p6uTJPK8eA0mWRMcXoH7_9PG7_ugBydcPXQXBVBYv0EVq0_fShpvjSwjRG7xo'

export const FIREBASE_MEASUREMENT_ID =
  env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'G-E9HWJE3026'
