import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsEndToEndTesting } from 'kat/lib/state/getters/getIsEndToEndTesting'
import { getIsMediumScreen } from 'kat/lib/state/getters/getIsMediumScreen'

import {
  // ModalLayoutConnectedProps,
  ModalLayoutMapStateReturnType,
} from './types'

export const ModalLayoutMapState = (
  state: StateReducers
  // props: ModalLayoutConnectedProps,
): ModalLayoutMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showModal = toggles && toggles.showModal
  const type = showModal ? showModal.type : undefined
  const id = showModal ? showModal?.id : undefined
  const isEndToEndTesting = getIsEndToEndTesting({ state })
  const isMediumScreen = getIsMediumScreen({ state })

  return {
    isEndToEndTesting,
    type,
    isMediumScreen,
    id,
  }
}
